<template>
  <div class="zn-approve-detail">
    <bhNavBar title="审批详情"></bhNavBar>
    <div class="fy-position-detail">
      <div class="fy-position-list-content-item">
        <div class="header">
          <div class="header-left">
            <div class="pos-name">
              <div style="max-width: 90%;line-height: 20px;">{{position.posName}}</div>
              <div class="prioroty">{{ priority(position.priority) }}</div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="cus-name">{{ position.customerName }}</div>
          <div class="footer">
            <div>
              创建时间: {{ parseTime(position.createdTime) }}
            </div>
          </div>
        </div>
      </div>
      <div class="base-info">
        <div class="base-info-title">
          <span>基本信息</span>
        </div>
        <div class="base-info-content">
          <div class="base-info-first-row">
            <span class="base-info-cell-label">开放数量</span>
            <span class="base-info-cell-content">{{position.miniOpenHc}}</span>
          </div>
          <div class="base-info-first-row">
            <span class="base-info-cell-label">佣金范围</span>
            <span class="base-info-cell-content">{{ commissionRange(position.miniMinCommission, position.miniMaxCommission) }}</span>
          </div>
          <div v-if="position.industry" class="base-info-first-row">
            <span class="base-info-cell-label">行业</span>
            <span class="base-info-cell-content">{{position.industry}}</span>
          </div>
          <div v-if="position.function" class="base-info-first-row">
            <span class="base-info-cell-label">职能</span>
            <span class="base-info-cell-content">{{position.function}}</span>
          </div>
          <div class="base-info-first-row">
            <span class="base-info-cell-label">工作年限要求</span>
            <span class="base-info-cell-content">{{workingYearRange(position.workingYearsStart, position.workingYearsEnd)}}</span>
          </div>
          <div class="base-info-first-row">
            <span class="base-info-cell-label">年薪范围</span>
            <span class="base-info-cell-content">{{annualSalaryRange(position.annualSalaryRangeStart,position.annualSalaryRangeEnd)}}</span>
          </div>
          <div class="base-info-first-row">
            <span class="base-info-cell-label">城市</span>
            <span class="base-info-cell-content">{{position.city}}</span>
          </div>
        </div>
      </div>
      <div class="base-info">
        <div class="base-info-title">
          <span>职位介绍</span>
        </div>
        <div class="base-info-content" v-if="position.informationOfPosition">
          {{ position.informationOfPosition }}
        </div>
      </div>
      <div class="base-info">
        <div class="base-info-title">
          <span>职位要求</span>
        </div>
        <div class="base-info-content" v-if="position.highlightsAndAdvantages">
          {{position.highlightsAndAdvantages}}
        </div>
      </div>
      <div class="base-info">
        <div class="base-info-title">
          <span>寻访方向</span>
        </div>
        <div class="base-info-content" v-if="position.searchDirection">
          {{position.searchDirection}}
        </div>
      </div>
      <div class="base-info">
        <div class="base-info-title">
          <span>薪资结构</span>
        </div>
        <div class="base-info-content" v-if="position.salaryStructure">
          {{ position.salaryStructure }}
        </div>
      </div>
    </div>
    <div class="bottom-Site"></div>
    <div class="bottom">
      <div class="buttomButton saveButton" @click="leftReturn">返回</div>
<!--      <div class="buttomButton saveButton" @click="handleSave">通过</div>-->
    </div>
  </div>
</template>

<script>
import bhNavBar from "@/components/bright/bh-navBar/navBar"
import { Row, Col } from 'vant'
import { znPositionApproveDetail } from "@/getData/getData";
import { localStorageGet, parseTime } from "@/libs/util"
import { DICTIONARY, ALLUSERDICTIONARY } from "@/getData/mutation-types";

import {responseUtil} from "@/libs/brightUtil";

export default {
  name: "znPartTimePositionApproveDetails",
  components: {
    bhNavBar,
    [Row.name]: Row,
    [Col.name]: Col
  },
  data() {
    return {
      position: {}
    }
  },
  watch: {
    $route: {
      handler(to) {
        this.getDetails(to.query.id)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    parseTime,
    leftReturn() {
      this.$router.go(-1);
    },
    handleSave() {
      // 连接口啊
    },
    priority(param) {
      const _priority = param
      let star = ''
      if (_priority === 3) {
        star = '高'
      } else if (_priority === 2) {
        star = '中'
      } else if (_priority === 1) {
        star = '低'
      }
      return star
    },
    commissionRange(min, max) {
      if (
          !min &&
          !max
      ) {
        return '未知'
      }
      return min + '元 - ' + max + '元'
    },
    annualSalaryRange(infoStart, infoEnd) {
      if (
          !infoStart &&
          !infoEnd
      ) {
        return '未知'
      }
      const start = infoStart
          ? infoStart.valueOf() + '万'
          : '不限'
      const end = infoEnd
          ? infoEnd.valueOf() + '万'
          : '不限'
      return start + ' - ' + end
    },
    workingYearRange(infoStart, infoEnd) {
      if (!infoStart && !infoEnd) {
        return '未知'
      }
      const start = infoStart
          ? infoStart.valueOf() + '年'
          : '不限'
      const end = infoEnd
          ? infoEnd.valueOf() + '年'
          : '不限'
      return start + ' - ' + end
    },
    getUserName(userId) {
      if (!userId) {
        return '未知'
      }
      if (typeof userId !== 'string') {
        userId = userId.toString()
      }
      const userList = localStorageGet(ALLUSERDICTIONARY)
      const arrUserIds = userId.split(',')
      let consultantNames = ''
      arrUserIds.forEach((item, index) => {
        const filterArr = userList.filter((user) => user.userId === item)
        if (filterArr.length > 0) {
          consultantNames += index === arrUserIds.length - 1 ? filterArr[0].username : filterArr[0].username + ','
        }
      })
      if (consultantNames !== '') {
        return consultantNames
      } else {
        return '未知'
      }
    },
    getDict(code) {
      if (code) {
        const firstCode = code.substring(0, 3)
        const DICT = localStorageGet(DICTIONARY)
        const arr = DICT[firstCode]
        if (arr) {
          const filterArr = arr.filter((item) => item.code === code)
          if (filterArr.length > 0) {
            return arr.filter((item) => item.code === code)[0]['label']
          }
        }
      }
      return code
    },
    getDetails(id) {
      var that = this
      znPositionApproveDetail(id).then(function (res) {
        // eslint-disable-next-line no-console
        responseUtil.dealResponse(that, res, () => {
          that.position = res.data[1]
        })
      })
    },
  }
}
</script>

<style lang="less" scoped>
.zn-approve-detail {
  .fy-position-detail {
    background-color: #F6F6F6;
    padding-top: 8px;
  }
  .fy-position-detail .fy-position-list-content-item {
    padding: 12px 17px 9px 10px;
    background: #FFFFFF;
    margin-bottom: 8px;
  }

  .fy-position-list-content-item .header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .location-top .location{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 4px;
    margin-bottom: 8px;
    background: #F1F4FD;
    border-radius: 4px;
  }

  .location span {
    margin-left: 4px;
    font-family: PingFang SC;
    font-size: 12px;
    color: #3D3D3D;
    line-height: 14px;
  }

  .fy-position-list-content-item .header .header-left .pos-name {
    font-family: PingFang SC;
    font-size: 16px;
    color: #3D3D3D;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .fy-position-list-content-item .header-right {
    margin-top: 8px;
    padding: 2px 8px;
    display: inline-flex;
    background: #31D5EC;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 12px;
    font-family: PingFang SC;
    line-height: 14px;
  }

  .fy-position-list-content-item .content .cus-name {
    margin-top: 8px;
    font-family: PingFang SC;
    font-size: 12px;
    color: #949C9F;
  }

  .fy-position-list-content-item .content .detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
  }

  .fy-position-list-content-item .content .detail .detail-item {
    padding: 2px 4px;
    background: #F1F4FD;
    border-radius: 4px;
    font-family: PingFang SC;
    font-size: 10px;
    line-height: 12px;
    color: #3D3D3D;
    margin-right: 8px;
  }

  .fy-position-list-content-item .footer {
    margin-top: 8px;
    font-family: PingFang SC;
    font-size: 10px;
    color: #949C9F;
  }

  .fy-position-list-content-item .footer .detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
  }

  .fy-position-list-content-item .footer .detail .detail-item {
    padding: 2px 4px;
    background: #5E5ADB;
    border-radius: 4px;
    font-family: PingFang SC;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    margin-right: 8px;
  }

  .divide-line {
    width: 100%;
    height: 0;
    border: 1px solid #F1F4FD;
    margin: 8px 0;
  }

  .pos-tag {
    width: 22px;
    height: 10px;
    margin-left: 8px;
  }

  .base-info {
    margin-top: 8px;
    background-color: #FFFFFF;
    padding: 12px 17px 9px 10px;
  }

  .base-info-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .base-info-title .base-info-icon {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #DFDEFC;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .base-info-title span {
    margin-left: 4px;
    font-family: PingFang SC;
    font-size: 16px;
    color: #2F3135;
  }

  .base-info-content {
    margin-top: 8px;
    font-size: 12px;
    color: #3D3D3D;
    line-height: 1.5;
    white-space:pre-wrap;
  }

  .location-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .update-time {
    color: #949C9F;
    font-size: 10px;
  }

  .fy-position-detail-footer {
    background-color: #FFFFFF;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    justify-content: space-evenly;
  }


  .footer-btn1 {
    font-family: PingFang SC;
    padding: 8px 32px;
    border: 2px solid ;
    border-image: linear-gradient(106deg, #28E5EA 14%, #6188F8 87%) 2;
    background: linear-gradient(106deg, #28E5EA 14%, #6188F8 87%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    clip-path: inset(0 round 8rpx);
  }

  .footer-btn2 {
    border-radius: 4px;
    font-family: PingFang SC;
    font-size: 18px;
    padding: 8px 32px;
    background: linear-gradient(106deg, #28E5EA 14%, #6188F8 87%);
    color: #FFFFFF;
  }
  .base-info-first-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
  }
  .base-info-cell-label {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0px;
    height: 14px;
    color: #979797;
    padding: 0 0 8px 16px;
  }

  .base-info-cell-content {
    font-size: 14px;
    letter-spacing: 0em;
    color: #000000;
    padding-left: 16px;
    width: 85%;
    word-break: break-word;
  }
  .prioroty {
    cursor: pointer;
    display: flex;
    margin-left: 8px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    background: #F9C803;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
  }
  .bottom-Site{
    width: 100%;
    height: 60px;
  }
  .bottom {
    width: 100%;
    height: 50px;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    bottom: 0px;
    background-color: rgba(248, 248, 248, 1);
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
  }
  .saveButton {
    color: white;
    border: 1px solid #6D00FB;
    background: linear-gradient(90deg, #1500FB 0%, #5400FB 47%, #6D00FB 99%);
  }
  .cancleButton {
    color: black;
    border: 1px solid black;
  }
  .buttomButton {
    width: 60px;
    height: 32px;
    font-size: 14px;
    text-align: center;
    line-height: 35px;
    margin-right: 15px;
    border-radius: 8px;
  }
}
</style>
